import React from 'react';
import { cn } from '@/utils';
import Spacer from './Spacer';

/**
 * a custom react component for input
 * @param {Object} props
 * @param {String | Number} props.value
 * @param {Function} props.onChange
 * @param {String} props.className
 * @param {String} props.id for hmtlFor for input to be connected with label, should be unique id
 * @param {String} props.label
 * @param {String} props.type
 * @param {JSX.Element} props.inputJsx
 * @param {String} props.error
 * @returns {JSX.Element}
 */

export default function Input({
  className,
  label,
  id,
  inputJsx,
  error,
  maxChar = null,
  ...props
}) {
  if (!inputJsx) {
    if (props?.type === 'textarea') {
      inputJsx = (
        <textarea
          id={id || label}
          className={cn(
            'rounded-lg text-white border-slate-600 border-[1px] bg-slate-900 p-2 w-full',
            className
          )}
          {...props}
        />
      );
    } else {
      inputJsx = (
        <input
          id={id || label}
          className={cn(
            'rounded-lg text-white border-slate-600 border-[1px] bg-slate-900 p-2 w-full',
            className
          )}
          {...props}
        />
      );
    }
  }

  return (
    <div>
      <label className="text-sm font-semibold text-black" htmlFor={id || label}>
        {label}
      </label>
      <Spacer size="small" />
      {maxChar && (
        <div className="flex justify-end w-full">
          <p className="text-xs text-slate-500">
            {props.value?.trim().length}/{maxChar}
          </p>
        </div>
      )}
      {inputJsx}
      {error && (
        <div className="h-4 mt-1">
          <p className="text-xs text-red-400">{error}</p>
        </div>
      )}
    </div>
  );
}
